<template>
    <div class="container-search">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="单号查询"></Title>
            <!-- 输入框 -->
            <div class="searchbox">
                <!-- 筛选 箭头 -->
                <div class="search-function">
                    <div
                        v-for="type in ['express', 'order']"
                        :key="type"
                        :class="{ 'search-option': true, active: seleted === type }"
                        @click="changeSeleted(type)"
                    >
                        {{ type === 'express' ? '物流单号' : '订单号' }}
                    </div>
                </div>
                <input
                    class="search-input"
                    v-model="number"
                    type="text"
                    @keyup.enter="search()"
                    :placeholder="seleted == 'express' ? '请输入物流单号' : '请输入订单号'"
                />
                <button class="search-btn" @click="search()">
                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                </button>
            </div>
        </div>
        <div class="search-mainbox">
            <div class="search-left">
                <div class="search-min-title" style="padding: 0 40px">物流信息</div>
                <van-loading v-show="loadingExp" style="padding: 10px 40px" color="#1989fa" />
                <!-- 表格 -->
                <ExpressTable :tableData="tableData" v-if="tableData" :showMobile="true" />
                <!-- <div class="common-bottombox" v-if="tableData">
                    <Pager :pageSizes="[10, 100]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
                </div> -->
            </div>
            <div class="search-right">
                <div class="search-min-title" style="padding: 0 20px">发货照片</div>
                <van-loading style="padding: 10px 20px" color="#1989fa" v-if="loadingPhoto" />
                <div class="search-modalbox-photo-box">
                    <div class="search-modalbox-photo" v-if="photoTimes">
                        <!-- 搜索框 -->
                        <div class="search-right-addcheckbox">
                            <div class="search-photo-search">
                                <input v-model="photoTimeSearchText" class="search-photo-input" type="text" placeholder="请输入 或 选择发货时间" />
                                <!-- 搜索按钮 -->
                                <button class="search-photo-search-btn" @click="searchPhotoBtn()">
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                                </button>
                            </div>
                            <div class="search-right-addcheckbox-title">发货时间</div>
                            <div class="search-photo-time-box" v-if="photoTimes && photoTimes.length > 0">
                                <div class="search-photo-time" v-for="item in photoTimes" :key="item" @click="clickPhotoTime(item)">
                                    <div>{{ item }}</div>
                                </div>
                            </div>
                            <div class="search-photo-time-tip" v-else-if="photoTimes && photoTimes.length <= 0">无发货时间</div>
                            <van-loading style="padding: 10px 40px" color="#1989fa" v-else />
                        </div>
                        <!-- Loading -->
                        <div class="search-right-addcheckbox" v-if="photos && photos.length > 0">
                            <div class="search-right-addcheckbox-title">照片</div>
                            <viewer class="search-photo-box" ref="viewer" :images="photos" :options="photoOptions">
                                <img class="search-photo-one" :src="item.src" :data-source="item.dataSource" v-for="item in photos" alt="" />
                                <div class="search-photo-loadmore" @click="loadMorePhoto()" v-if="this.photoLoadPage < 10">加载更多</div>
                                <div class="search-photo-loadmore-end" v-else>到底了</div>
                            </viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Navbar from '@/components/common/navbar'
import ExpressTable from '@/components/express/expressTable'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast } from 'vant' // 保留 Toast，因为代码中有使用
import { apiGetExpressList, apiGetExpressPhoto, apiGetPackList } from '@/request/api'
import download from 'downloadjs'

export default {
    components: {
        Navbar,
        ExpressTable,
        Title,
        Pager,
        VueViewer, // 局部注册 VueViewer
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loadingExp: false,
            loadingPhoto: false,
            tableData: null,
            seleted: 'express',
            tablePage: {
                currentPage: 1,
                pageSize: 50,
                totalResult: 0,
            },
            historyData: null,
            number: null,
            device: this.GLOBAL.device,
            //

            photoTimes: null,
            photoTimeSearchText: null,
            photoLoadPage: 1,
            photos: [],
            photoOptions: {
                toolbar: {
                    zoomIn: 2,
                    zoomOut: 2,
                    oneToOne: 2,
                    reset: 2,
                    rotateLeft: 2,
                    rotateRight: 2,
                    prev: 1,
                    next: 1,
                    flipHorizontal: 0,
                    flipVertical: 0,
                    play: 0,
                    download: {
                        show: 1,
                        title: '下载图片',
                        icon: 'custom-icon-download', // 自定义图标类名，需要自己定义CSS样式
                        click: () => {
                            const viewer = this.$refs.viewer
                            const index = viewer.$viewer.index
                            const img = viewer.images[index]
                            console.log('点击的图片:', img)
                            if (img) {
                                let url = img.dataSource
                                let filename = img.filename
                                this.downloadItem(url, filename)
                            } else {
                                console.log('未找到图片元素')
                            }
                        },
                    },
                },
                title: 2,
                url: 'data-source',
                zIndex: 10000,
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {},
        async search() {
            if (!this.number) {
                Toast.fail('请输入单号')
                return
            }
            this.resetSearchState()
            await this.getList()
            if (this.seleted === 'express') await this.getPhotoTime(this.number)
        },
        resetSearchState() {
            this.tableData = null
            this.photos = []
            this.photoTimes = null
            this.photoLoadPage = 1
            this.photoTimeSearchText = null
        },
        async getList() {
            this.loadingExp = true
            const params = {
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
                [this.seleted === 'express' ? 'number' : 'orderNumber']: this.number.trim(),
            }

            try {
                const res = await apiGetExpressList(params)
                this.tableData = res.data.items
                this.tablePage.totalResult = res.data.count
                if (this.seleted === 'order') {
                    await this.getPhotoTime(this.tableData[0].number)
                }
            } catch (error) {
                console.warn(error)
            } finally {
                this.loadingExp = false
            }
        },
        changeSeleted(type) {
            this.seleted = type
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        //发货时间和照片
        async getPhotoTime(value) {
            this.loadingPhoto = true
            try {
                const res = await apiGetPackList({ number: value })
                this.photoTimes = [...new Set(res.data.items.map((item) => this.formatTimePhoto(item.createTime)))]
                if (this.photoTimes.length > 0) {
                    this.photoTimeSearchText = this.photoTimes[0]
                    await this.searchPhoto()
                }
            } catch (error) {
                Toast.clear()
            } finally {
                this.loadingPhoto = false
            }
        },
        async searchPhoto(time = null) {
            const params = { filename: time || this.photoTimeSearchText }
            Toast.loading({ message: '加载照片中', forbidClick: true, duration: 0 })
            try {
                const res = await apiGetExpressPhoto(params)
                const photos = res.data || []

                if (photos.length === 0) {
                    Toast.fail(`${params.filename}没有照片`)
                    return
                }

                const newPhotos = photos.map((photo) => ({
                    src: `https://mrp-express-photo.moemee.com/${photo}?x-oss-process=style/sq`,
                    dataSource: `https://mrp-express-photo.moemee.com/${photo}?x-oss-process=style/hd`,
                    filename: photo.split('/').pop(),
                }))
                this.photos.push(...newPhotos)

                if (this.photoLoadPage <= 1) {
                    this.loadMorePhoto()
                }
            } catch (error) {
                console.warn(error)
            } finally {
                Toast.clear()
            }
        },
        loadMorePhoto() {
            const newTime = this.timeMore(this.photoLoadPage)
            this.photoLoadPage++
            this.searchPhoto(newTime)
        },
        timeMore(value) {
            if (!this.photoTimeSearchText) return
            const [data, time] = this.photoTimeSearchText.split('_')
            let hour = parseInt(time.slice(0, 2))
            let minute = parseInt(time.slice(2, 4)) - value

            if (minute < 0) {
                minute = 59
                hour = (hour - 1 + 24) % 24
            }

            return `${data}_${hour.toString().padStart(2, '0')}${minute.toString().padStart(2, '0')}`
        },
        downloadPhoto() {
            const viewer = this.$refs.viewer.viewer
            if (viewer) {
                const img = viewer.image
                if (img) {
                    // 创建并触发下载
                    const a = document.createElement('a')
                    a.href = img.src
                    a.download = img.alt || 'download'
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
            }
        },
        formatTimePhoto(value) {
            const time = new Date(value)
            const year = time.getFullYear()
            const month = (time.getMonth() + 1).toString().padStart(2, '0')
            const date = time.getDate().toString().padStart(2, '0')
            const hours = time.getHours().toString().padStart(2, '0')
            const minute = time.getMinutes().toString().padStart(2, '0')
            return `${year}${month}${date}_${hours}${minute}`
        },
        downloadItem(dataURL, filename) {
            let x = new XMLHttpRequest()
            x.open('GET', dataURL, true)
            x.responseType = 'blob'
            x.onload = function (e) {
                download(e.target.response, filename, 'image/jpeg')
            }
            x.send()
        },
    },
}
</script>

<style scoped>
.container-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* 搜索区域 */
.searchbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.search-input {
    flex: 1;
    border: 1px solid #e9e9eb;
    outline: none;
    height: 32px;
    border-radius: 16px 0 0 16px;
    padding: 0 15px;
    font-size: 14px;
}

.search-btn {
    width: 80px;
    border: none;
    outline: none;
    height: 32px;
    background-color: #e9e9eb;
    color: #333;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
}

.search-btn:hover {
    opacity: 0.7;
}

/* 选择分类 */
.search-function {
    width: 190px;
    margin-right: 10px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.search-option {
    background-color: #e9e9eb;
    color: #999;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    border-right: 1px solid #fff;
    flex: 1;
}

.search-option:last-child {
    border-right: none;
}

.search-option.active {
    background-color: #000;
    color: #fff;
}

.search-option:hover {
    color: #333;
}

.search-mainbox {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.search-left {
    width: 50%;
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}

.search-right {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search-min-title {
    font-size: 14px;
    font-weight: bold;
}

.search-right-addcheckbox {
    margin-bottom: 15px;
}

.search-right-addcheckbox-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}

/*  */

.search-modalbox-photo-box {
    overflow-y: auto;
    flex: 1;
    padding: 15px 20px 15px;
}

.search-modalbox-photo {
    background: #fbfbfb;
    border: 1px solid #efeeee;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
}

.search-right-addcheckbox {
    margin-bottom: 15px;
}

.search-right-addcheckbox-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}

.search-photo-time-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.search-photo-time {
    font-size: 14px;
    padding: 4px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: #e9e9eb;
    color: #666;
    border-radius: 6px;
    cursor: pointer;
}

.search-photo-time-tip {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.search-photo-search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.search-photo-input {
    flex: 1;
    border: 1px solid #e9e9eb;
    outline: none;
    height: 32px;
    border-radius: 16px 0 0 16px;
    padding: 0 15px;
    font-size: 14px;
}

.search-photo-search-btn {
    width: 80px;
    border: none;
    outline: none;
    height: 32px;
    background-color: #e9e9eb;
    color: #333;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
}

.search-photo-box {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 5px;
}

.search-photo-one {
    display: block;
    width: 19%;
    height: auto;
    margin-right: 1%;
    margin-bottom: 1%;
    cursor: pointer;
    border-radius: 2%;
}

@media screen and (max-width: 768px) {
    .search-photo-one {
        width: 49%;
        margin-right: 1%;
    }

    .search-mainbox {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .search-left {
        width: 100%;
        /* min-height: 400px; */
    }

    .search-right {
        width: 100%;
        display: block;
    }

    .search-min-title {
        padding: 0 20px !important;
        margin-top: 15px;
    }

    .search-modalbox-photo-box {
        padding: 15px;
    }
}

.search-photo-loadmore {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 15px;
    background-color: #b8a3ed;
}

.search-photo-loadmore:hover {
    background-color: #a18de0;
}

.search-photo-loadmore-end {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #666;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 15px;
}

/* mobile */
@media screen and (max-width: 480px) {
    .searchbox {
        padding: 0 15px;
        flex-wrap: wrap;
    }

    .search-function {
        width: 100%;
        height: 28px;
        border-radius: 14px;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .search-option {
        height: 28px;
        line-height: 28px;
        font-size: 13px;
    }

    .search-input {
        height: 40px;
        border-radius: 20px 0 0 20px;
    }

    .search-btn {
        height: 40px;
        border-radius: 0 20px 20px 0;
    }
}
</style>

<style>
.viewer-download {
    color: #fff;
    font-size: 0.45rem;
    line-height: 1.5rem;
    text-align: center;
}

.viewer-download::before {
    content: '下载';
}
</style>
