<template>
    <div :class="anime ? 'container-packaging anime' : 'container-packaging'">
        <!-- 标题区域 -->
        <div class="common-topbox">
            <Title :title="'打包扫码(今日本机:' + todayNumber + ')'">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main" @click="openHistory()">扫描记录</div>
                </template>
            </Title>
            <!-- 输入框 -->
            <div class="scanInput">
                <!-- 筛选 箭头 -->
                <input class="scanInput-input" v-model="number" type="text" @keyup.enter="submit()" placeholder="请输入物流单号" ref="inputRef" autofocus />
                <button class="scanInput-btn" @click="submit()">提交</button>
            </div>
            <div class="info">
                <div class="info-drive">设备: {{ drive }}</div>
            </div>
        </div>
        <div class="common-midbox">
            <div class="tablebox">
                <vxe-table round border highlight-hover-row resizable ref="xTable" :data="inputHistory" height="100%" show-overflow>
                    <!-- <vxe-table-column type="seq" width="60"></vxe-table-column> -->
                    <vxe-table-column field="createTime" width="180" :formatter="formatTime" title="扫码时间"></vxe-table-column>
                    <vxe-table-column field="number" title="快递单号"></vxe-table-column>
                </vxe-table>
            </div>
            <audio id="audio" src="https://mrp.moemee.com/oss-assets/audio/scan.mp3" controls="controls" hidden></audio>
            <audio id="audio2" src="https://mrp.moemee.com/oss-assets/audio/fail.mp3" controls="controls" hidden></audio>
            <audio id="audio3" src="https://mrp.moemee.com/oss-assets/audio/repeat.mp3" controls="controls" hidden></audio>
        </div>
        <!-- 弹窗 扫描记录 -->
        <van-popup
            v-model="showHistoryModal"
            position="right"
            :style="{ width: '40%', minWidth: '320px', height: '100%', display: 'flex', flexDirection: 'column' }"
            closeable
        >
            <div class="history-modal-title">扫描记录</div>
            <div class="scanInput history-modal-scanInput">
                <!-- 筛选 箭头 -->
                <input
                    class="scanInput-input"
                    v-model="searchNumber"
                    type="text"
                    @keyup.enter="submit()"
                    placeholder="请输入物流单号"
                    ref="inputRef"
                    autofocus
                />
                <button class="scanInput-btn" @click="getInfo()">查询</button>
            </div>
            <div class="history-modal-tips" v-if="historyData.length > 0">共{{ tablePage.totalResult }}条记录</div>
            <div class="history-modal-tips" v-if="historyData.length == 0">
                <div style="text-align: center; padding: 20px 0; color: #999">暂无数据</div>
            </div>
            <div class="history-modal-content" v-if="historyData">
                <div class="history-modal-card" v-for="item in historyData">
                    <div class="time">
                        {{ formatTime({ cellValue: item.createTime }) }}
                    </div>
                    <div class="number">快递单号：{{ item.number }}</div>
                    <div class="remark">
                        <span>备注：{{ item.remarks || '无' }}</span>
                        <span v-if="item.equipInfo">设备：{{ item.equipInfo }}</span>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import Title from '@/components/common/title'
import { apiGetPackList, apiAddPack } from '@/request/api'

export default {
    components: {
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
            number: null,
            inputHistory: [],
            anime: false,
            lastNumber: null,
            showHistoryModal: false,
            historyData: [],
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0,
            },
            searchNumber: null,
            todayNumber: 0,
            drive: null,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 读取本地存储
            let today = new Date().toLocaleDateString()
            let localStorageToday = localStorage.getItem('today')
            console.log(today, localStorageToday)
            if (today != localStorageToday) {
                console.log('不是同一天')
                localStorage.setItem('today', today)
                localStorage.setItem('todayNumber', 0)
                this.todayNumber = 0
            } else {
                console.log('是同一天')
                this.todayNumber = parseInt(localStorage.getItem('todayNumber'))
            }
            this.$nextTick(() => {
                // 使用引用的原生DOM对象input的focus方法自动获得焦点
                this.$refs.inputRef.focus()
            })
            this.getDriveInfo()
        },
        getDriveInfo() {
            var ua = navigator.userAgent.split('(')[1].split(')')[0]
            this.drive = ua
            if (this.drive.length > 127) {
                this.drive = this.drive.slice(-127)
            }
        },
        openHistory() {
            this.searchNumber = null
            this.showHistoryModal = true
            this.getInfo()
        },
        getInfo() {
            // GET
            let self = this
            let params = {
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
            }
            if (this.searchNumber) {
                params.number = this.searchNumber
            }
            apiGetPackList(params)
                .then((res) => {
                    self.historyData = res.data.items
                    self.tablePage.totalResult = res.data.count
                })
                .catch((err) => {
                    console.warn(err)
                })
        },
        submit() {
            let self = this
            //删除头尾空格
            this.number = this.number.trim()
            if (this.number == this.lastNumber) {
                this.number = null
                document.getElementById('audio3').volume = 1
                document.getElementById('audio3').play()
                this.$toast.fail('请勿重复提交')
                return
            }
            // 是否为空
            if (!this.number) {
                this.number = null
                this.submitFailer('请输入物流单号')
                return
            }
            // 判断长度
            if (this.number.length < 13 || this.number.length > 16) {
                this.number = null
                this.submitFailer('请输入正确的物流单号')
                return
            }
            // 判断是否包含非英文和数字
            let reg = /^[0-9a-zA-Z]+$/
            if (!reg.test(this.number)) {
                this.number = null
                this.submitFailer('请输入正确的物流单号')
                return
            }
            this.lastNumber = JSON.parse(JSON.stringify(this.number))
            // POST
            let params = {
                number: this.number,
                remarks: null,
                equipInfo: this.drive,
                deviceCreateTime: new Date().getTime(),
            }
            apiAddPack(params)
                .then((res) => {
                    console.log(res)
                    self.submitSuccess(params)
                })
                .catch((err) => {
                    console.warn(err)
                    self.submitFailer('网络错误')
                })
        },
        submitFailer(text) {
            this.lastNumber = '0'
            // Todo 错误的音乐
            document.getElementById('audio2').volume = 1
            document.getElementById('audio2').play()
            console.warn(text)
            this.$toast.fail(text)
        },
        submitSuccess(params) {
            if (params.number) {
                // 播放声音
                // 设置音量为100
                document.getElementById('audio').volume = 1
                document.getElementById('audio').play()
                // 保存数据
                let obj = params
                obj.createTime = obj.deviceCreateTime
                // 前面插入obj
                this.inputHistory.unshift(obj)
                this.anime = true
                this.number = null
                this.$refs.inputRef.focus()
                setTimeout(() => {
                    this.anime = false
                }, 500)
            }
            this.$toast.success('提交成功')
            // 统计量
            let today = new Date().toLocaleDateString()
            console.log(today)
            localStorage.setItem('today', today)
            let localStorageNumber = localStorage.getItem('todayNumber')
            let todayNumber = parseInt(localStorageNumber) + 1
            this.todayNumber = todayNumber
            localStorage.setItem('todayNumber', todayNumber)
        },
        formatTime({ cellValue }) {
            let time = new Date(cellValue)
            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let date = time.getDate()
            let hours = time.getHours()
            let minute = time.getMinutes()
            let second = time.getSeconds()

            if (month < 10) {
                month = '0' + month
            }
            if (date < 10) {
                date = '0' + date
            }
            if (hours < 10) {
                hours = '0' + hours
            }
            if (minute < 10) {
                minute = '0' + minute
            }
            if (second < 10) {
                second = '0' + second
            }
            return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
        },
    },
}
</script>

<style scoped>
.container-packaging {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;
}

.container-packaging.anime {
    background-color: rgb(74, 225, 129);
}

.container-packaging.anime .common-topbox {
    background-color: rgb(74, 225, 129);
}

.container-packaging.anime .toptitle-titlebox {
    border-color: rgb(74, 225, 129);
}

.scanInput {
    width: 100%;
    padding: 20px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scanInput-input {
    flex: 1;
    border: 1px solid #e9e9eb;
    outline: none;
    height: 32px;
    border-radius: 16px 0 0 16px;
    padding: 0 15px;
    font-size: 14px;
}

.scanInput-btn {
    width: 80px;
    border: none;
    outline: none;
    height: 32px;
    background-color: #e9e9eb;
    color: #333;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
}

.tablebox {
    width: 100%;
    height: 100%;
}

.container-packaging .common-midbox {
    padding-bottom: 15px;
}

/* mobile */
@media screen and (max-width: 480px) {
    .scanInput {
        padding: 20px 15px 10px;
    }

    .scanInput-input {
        height: 40px;
        border-radius: 20px 0 0 20px;
    }

    .scanInput-btn {
        height: 40px;
        border-radius: 0 20px 20px 0;
    }
}

.history-modal-title {
    padding: 14px 0;
    font-size: 16px;
    text-align: center;
    color: #333;
    font-weight: bold;
}

.history-modal-content {
    flex: 1;
    overflow: auto;
    padding: 0 15px;
    /* margin-top: 10px; */
}

.history-modal-card {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e9e9eb;
}

.history-modal-card .time {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.history-modal-card .number {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
    font-weight: bold;
}

.history-modal-card .remark {
    font-size: 14px;
    color: #999;
}

.history-modal-scanInput {
    padding: 0 15px;
}

.history-modal-tips {
    padding: 15px 15px 5px;
    font-size: 14px;
    color: #999;
    text-align: left;
}

.info {
    padding: 0 15px;
}

.info-drive {
    text-align: left;
    color: #333;
    font-size: 12px;
    line-height: 1.2;
    font-weight: bold;
}

@media screen and (min-width: 481px) {
    .info {
        padding: 15px 0 0;
    }
}
</style>
