<template>
    <div class="container-desktop">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="欢迎使用MRP系统 v1.42">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main" @click="logout()" v-if="device == 'pc'">退出登录</div>
                </template>
            </Title>
        </div>
        <!-- mobile -->
        <div class="common-midbox">
            <!-- 功能列表 -->
            <Nav />
            <!-- 常用链接 -->
            <Link />
        </div>
        <!-- 待办事项 -->
        <!-- 常用功能 -->
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import Link from '@/components/desktop/link'
import Nav from '@/components/desktop/nav'
import { apiGetExpressList } from '@/request/api'

export default {
    components: {
        Navbar,
        Title,
        Link,
        Nav,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {},
        goUrl(item) {
            this.go(item.path)
        },
        go(where) {
            this.$router.push('/' + where)
        },
    },
}
</script>

<style scoped>
.container-desktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.container-desktop .common-midbox {
    height: auto;
    overflow-y: auto;
}

/* mobile */

.desktop-mobile-box {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background-color: #efefef;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.desktop-mobile-title {
    flex: 1;
    font-size: 16px;
}

.desktop-mobile-arrow {
    font-size: 14px;
    color: #aaa;
}

@media screen and (max-width: 480px) {
    .container-desktop .common-midbox {
        padding-top: 20px;
    }
}
</style>
