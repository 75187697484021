import { render, staticRenderFns } from "./expressTable.vue?vue&type=template&id=0fa50b68&scoped=true"
import script from "./expressTable.vue?vue&type=script&lang=js"
export * from "./expressTable.vue?vue&type=script&lang=js"
import style0 from "./expressTable.vue?vue&type=style&index=0&id=0fa50b68&prod&scoped=true&lang=css"
import style1 from "./expressTable.vue?vue&type=style&index=1&id=0fa50b68&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa50b68",
  null
  
)

export default component.exports